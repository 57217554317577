import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/whousesai.com/next.js/src/components/Header.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/whousesai.com/next.js/src/styles/globals.scss");
