"use client";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const navigation = [
  {
    name: "By Profession",
    href: "null",
    submenu: [
      { name: "For Lawyers", href: "/category/For%20Lawyers" },
      { name: "For Content Writers", href: "/category/for-content-writers" },
      { name: "For Cooks", href: "/category/for-cooks" },
      { name: "For Designers", href: "/category/for-designers" },
      { name: "For Teachers", href: "/category/for-teachers" },
      { name: "For Doctors", href: "/category/for-doctors" },
    ],
  },
  {
    name: "By Industry",
    href: null,
    submenu: [
      { name: "Healthcare", href: "/category/AI%20in%20Healthcare" },
      { name: "Automobile", href: "/category/for-automobile" },
      { name: "Interior Designing", href: "/category/for-interior-designing" },
      { name: "Construction", href: "/category/for-construction" },
      { name: "Architecture", href: "/category/for-architecture" },
    ],
  },
  {
    name: "How To",
    href: "/category/how-to",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Nav = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  const blogs = [
    { title: "Blog Title 1", href: "/blog/blog-title-1" },
    { title: "Blog Title 2", href: "/blog/blog-title-2" },
    { title: "Blog Title 3", href: "/blog/blog-title-3" },
    // Add more blog titles here
  ];

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = blogs.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredBlogs(filtered);
    } else {
      setFilteredBlogs([]);
    }
  }, [searchQuery]);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20">
      <Disclosure as="nav" className="bg-white">
        {({ open, close }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-0">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  {/* <div className="flex-shrink-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        alt="Logo"
                        width={100}
                        height={40}
                      />
                    </Link>
                  </div> */}
                  <div className="hidden sm:block sm:ml-6 lg:ml-0">
                    <div className="flex space-x-4">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black px-3 py-2 rounded-md text-sm font-medium flex items-center"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-56">
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md text-sm font-medium"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center space-x-4 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="relative">
                    <button
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setIsSearchOpen(!isSearchOpen)}
                    >
                      <FontAwesomeIcon icon={faSearch} size="lg" />
                    </button>
                    {isSearchOpen && (
                      <div className="absolute right-full mr-2 mt-1 w-64 bg-white shadow-lg rounded-md">
                        <input
                          type="text"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                          placeholder="Search blogs..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {filteredBlogs.length > 0 && (
                          <div className="mt-2">
                            {filteredBlogs.map((blog) => (
                              <Link
                                key={blog.title}
                                href={blog.href}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                {blog.title}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    Subscribe
                  </button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Nav;
